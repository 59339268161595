/* Buttons */
.btn {
  font-size: 14px;
  &:focus {
    outline: none;
    color: $text-light-primary;
  }
}

.btn-less-prominent {
    opacity: 0.65;
}

.btn-swurve {
    background-color: $main-black;
    border-color: $main-black;
    color: $text-light-primary;
    transition: 0.5s;
    &:hover {
        background-color: $main-swurquoise;
        border-color: $main-swurquoise;
        color: $text-light-primary;
    }
}

.btn-coral {
    background-color: $main-coral;
    border-color: $main-coral;
    color: $text-light-primary;
    transition: 0.5s;
    &:hover {
        background-color: $main-swurquoise;
        border-color: $main-swurquoise;
        color: $text-light-primary;
    }
}

.btn-swurve-inverse {
    background-color: $main-swurquoise;
    border-color: $main-swurquoise;
    color: $text-light-primary;
    transition: 0.5s;
    &:hover {
        background-color: $light-primary;
        border-color: $light-primary;
        color: $text-dark-primary;
    }
}

.btn-copy {
    width: 34px;
    height: 34px;
    padding: 0;

    img {
        width: 15px;
        height: 20px;
    }
}

.btn-light {
    background-color: $light-primary;
    border-color: $light-primary;
    color: $text-dark-primary;

    &:hover {
        background-color: $grey-primary;
        border-color: $grey-primary;
        color: $text-light-primary;
    }
}

.btn-neutral {
    background-color: $grey-thin;
    border-color: $grey-thin;
    color: $text-dark-primary;

    &:hover {
        background-color: $light-primary;
        border-color: $light-primary;
        color: $text-dark-primary;
    }
}
