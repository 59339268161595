/* Slider card styling */

.slider-container {
  width: 100%;
  max-height: 100%;
  padding: 2% 1%;
  p {
    height: 1.5em;
  }
}

.slider-control {
  width: 84%;
  max-width: 100%;
  margin: 1.5em auto;
  padding: 0.08em 1.4em 0.08em 0.1em;
  border: 0.09em solid #fff;
  border-radius: 1em;
  transform: rotate(-25deg);
}

.slider-wrapper {
  border: none;
  width: 100%;
  height: 1.2em;
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-content: center;
  align-items: center;
}

.ui-widget-content {
	background: none;
}
.ui-slider-horizontal .ui-slider-handle {
	top: 0;
	left: 0;
	margin: 0;
	width: 1.2em;
	height: 1.2em;
	&:hover{
		box-shadow: 0 0 0 0.13em rgba(250,250,255,0.7);
		cursor: pointer;
	}
}