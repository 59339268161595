/* Four question card Styling */

.fourQuestionContainer {
	width: 100%;
	.row{
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-content: center;
		align-items: center;
		width: 100%;
		margin: 0;
		*{
			display: flex;
			justify-content: center;
			align-content: center;
			align-items: center;
		}
	}
	.choice, .left, .right{
		padding: 0;
	}
	.choice{
		width: 36%;
		margin: 0;
	}
	.four-dragger {
		flex-flow: column;
		padding: 0;
		font-size: 0.8em;
		width: 28%;
		.row{
			margin: 0.3em 0;
		}
	}
	.left i{
		margin-right: 0.7em;
	}
	#left-choice{
	}
	.right i{
		margin-left: 0.7em;
	}
	#right-choice{
	}
	
	#bottom-choice{
		padding-top: 0.4em;
		margin:0;
	}
}

