@mixin custom-scroll-bar {
  &::-webkit-scrollbar {
      width: 7px;
      background-color: rgba(0,0,0,0);
      -webkit-border-radius: 100px;
  }

  &::-webkit-scrollbar:hover {
    background-color: rgba(0, 0, 0, 0.09);
  }

  &::-webkit-scrollbar-thumb:vertical {
    background: rgba(0,0,0,0.2);
    -webkit-border-radius: 100px;
  }
  &::-webkit-scrollbar-thumb:vertical:active {
    background: rgba(0,0,0,0.4);
    -webkit-border-radius: 100px;
  }
}

@mixin swurquoise-gradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#27ccc0+0,5248c4+100 */
  background: #27ccc0; /* Old browsers */
  background: -moz-linear-gradient(45deg,  #27ccc0 0%, #5248c4 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg,  #27ccc0 0%,#5248c4 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg,  #27ccc0 0%,#5248c4 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#27ccc0', endColorstr='#5248c4',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}

@mixin coral-gradient {
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#fe5d5c+0,edca57+100 */
  background: #fe5d5c; /* Old browsers */
  background: -moz-linear-gradient(45deg,  #fe5d5c 0%, #edca57 100%); /* FF3.6-15 */
  background: -webkit-linear-gradient(45deg,  #fe5d5c 0%,#edca57 100%); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(45deg,  #fe5d5c 0%,#edca57 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#fe5d5c', endColorstr='#edca57',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
