.Loading {
    


    /* Loading Content */
    #loading-content {
        
        background: #282828;
        
        font-family: 'Open Sans', sans-serif;

        
        z-index: 1000;
        position: absolute;
        color: $text-light-primary;
        width: 100vw;
        height: 100vh;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        
        #loading-hand {
            height: 7vh;
            animation: hand-swipe 2s;
            animation-iteration-count: infinite;
            margin-top: 15%;

        }
        
        #loading-text {
            font-size: 4vh;
            text-align: center;
            margin-left: 15%;
            margin-right: 15%;
            margin-top: 8vh;
        }

        .content-top {
            text-align: center;
            width: 100%;
            position: absolute;
            top: 72px;
            left: 0px;

            h1 {
                font-size: 52px;
                letter-spacing: 1px;
            }

            p {
                font-size: 22px;
            }
        }

        .content-bottom {
            text-align: center;
            width: 100%;
            position: absolute;
            bottom: 72px;
            left: 0px;


            p {
                font-size: 3vh;
            }
        }
    }
}

@keyframes hand-swipe {
  0% {
      opacity: 0;
      margin-left: 20%;
  }
  20% {
      opacity: 1;
  }
  80% {
      opacity: 1;
  }
  100% {
      opacity: 0;
      margin-left: 80%;
      transform: rotate(45deg);
  }
}