html, body {
  height: 100%;
}

body {
  background-color: #282828;
  color: #e6e7e8;
  overflow: hidden;
}

.viewer {
    background: $main-black no-repeat;
    background-size: cover;
    background-position: center;
    //This prevents flying cards from scrolling the page
    overflow: hidden;
}

.final-bottom {
    font-size: 15px;
    text-align: left;
    bottom: 0;
    position: absolute;
}

.desktop-instruction-text {
  margin-bottom: -30px;
  margin-top: 0px;
  padding-top: 20px;
  font-size: 18px;
  color: #e6e7e8;
  line-height: 25px;
}

.instruction-white {
  color:white;
  font-size: 18px;
  padding: 10px;
  text-align: center;
}

.page-center {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  flex-direction: column;
  img {
    display: flex;
    height: 30px;
  }
}

.closed-background {
    background-image: url("../../images/login-bg.png");
    background-size: cover;
    background-height: 100vh;
    background-position:center;
}