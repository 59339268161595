/* Offer card styling */

#offer {
  text-align: center;
  color: $main-swurquoise;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: contain;
  font-size: 1.5em;
  transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) 1s;
  background-repeat: no-repeat;
  background-image: url("https://app.swurveys.com/assets/swurveyslogo.png");
}

#offer > .a {
  color: white;
}

#offer.ng-hide {
  left: -100%;
}

.offer-button {
  font-family: sans-serif;
  background: $main-black;
  color: $text-light-primary;
  border: none;
  padding: 15px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 20px;
  font-weight: bold;
  border-radius: .5em;
  box-shadow: 0 1px 2px rgba(255, 255, 255, .2);
  cursor: default;
}

.offer-bottom {
  position: absolute;
  bottom: 0px;
  width: 100%;
}
