/* ui-text styling */

.text-center {
    text-align: center;
}

.text-left {
    text-align: left;;
}

.text-right {
    text-align: right;
}

.text-size-60 {
  font-size: 3em;
}

.caps {
    text-transform: uppercase;
}

.extra-mile-font {
    font-family: 'ExtraMile', Helvetica, Arial, Sans-Serif;
}

.full-text {
    width: 100%;
}

/*Style chevrons*/
.fa-chevron-up, .fa-chevron-up-solo, .fa-chevron-down, .fa-chevron-left, .fa-chevron-right {
  font-size: 1em;
}

.chevron-left-wrapper {
  padding-right: .5em;
}

.chevron-right-wrapper {
  padding-left: .5em;
}

.footer-color {
  color: $light-neutral;
}

.swurve-on img {
  height: 3vh;
}

.swurve-on h1 {
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
}

.testing-color {
  background-color: $text-dark-primary;
}

.sub-text {
  font-size: 20px;
}

.sum-text {
  font-size: 20px;
  margin-bottom: 9px;
  margin-left: 18px;
}

.sum-text-group{
  height: 63%;
}

.hint-text {
  font-size: 0.84em;
  font-weight: bold;
  text-align: center;
}

.top-text-wrapper {
  margin-bottom: 12%;
}

.bottom-text-wrapper {
  margin-top: 80px;
  margin-bottom: 20px;
}

.binary-text {
  width: 45%;
}

.watermark {
  background-color: $light-secondary;
  color: $text-dark-secondary;

  position: absolute;
  top: -50px;
  left: -105px;

  height: 120px;
  width: 230px;
  transform: rotate(-45deg);
  text-align: center;
}

.watermark h1{
  transform: rotateX(30%);
  -ms-transform: rotate(7deg);
  font-size: 2em;
  margin-top: 3em;
}


//Override bootstraps danger class with our coral color
.text-danger {
  color: $main-coral;
}
