/* style.css */
@import "mixins/index";
@import "variable", "animation", "text", "buttons", "inputs", "flex-layout", "spacing";


body {
  font-family: 'Source Sans Pro', sans-serif;
  background: $light-primary;
  color: $text-dark-primary !important;
}

a {
  cursor: pointer;
  color: $text-link;
  &:hover {
    color: lighten($text-link, 27);
  }
}

*:focus {
  outline: 0;
}

.clickable {
  cursor: pointer;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}


.full-view-height {
  height: 100vh;
}

.vertical-center {
  min-height: 100%;
  display: flex;
  align-items: center;
}

.nopointer {
  pointer-events: none;
  user-select: none;
}

.noselect {
  user-select: none;
}

.max-z {
  z-index: 9999;
}

.z-1 {
  z-index: 1;
}

.swurve-color {
  color: $text-link;
}

.h-center-block {
  margin-left: auto;
  margin-right: auto;
}

.hide {
  display: none;
}

.reset {
  clear: both;
}

.inline-center {
  display: inline-block;
}

.absolute-left {
  position: absolute;
  top: -25px;
  right: 17px;
}

.absolute-right {
  position: absolute;
  top: -25px;
  left: 17px;
}

.push-left {
  float: left;
  margin-left: 3.6%;
}

.push-right {
  float: right;
  margin-right: 3.6%;
}

.arrow {
  max-width: 100px;
  max-height: 100px;
}

.arrow img {
  max-width: 100px;
  max-height: 100px;
}

.no-padding-left {
  padding-left: 0;
}

.no-padding-right {
  padding-right: 0;
}

.padding-5 {
  padding: 5px;
}

.padding-left-5 {
  padding-left: 5px;
}

.padding-right-5 {
  padding-right: 5px;
}

.padding-top-15 {
  padding-top: 15px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-top-15 {
  margin-top: 15px;
}

.container-full {
  margin: 0 auto;
  width: 100%;
}

.img-container {
  width: 100%;
  text-align: center;
  min-height: 200px;
  max-height: 516px;
  margin: 0 auto;
  img {
    max-width: 100%;
  }
}

.disabled {
 color: $grey-neutral;
 pointer-events: none;
 user-select: none;
 .onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
   background-color: $grey-neutral;
 }
}

.info-container {
  position: relative;
  z-index: 3;
}

.info-content {
  position: absolute;
  top: -0.5em;
  left: 0;
  text-align: left;
  border: 2px solid;
  border-radius: 6px;
  color: white;
  background-color: #27ccc0;
  width: max-content;
  max-width: 36em;
  padding: 0.5em;
  font-size: 1.4rem;
  font-weight: normal;
  line-height: 1.5em;
}

.font-reset {
  font-size: initial;
}

.info-container a:link {
  color: black;
  text-decoration: underline;
}

.info-container a:visited {
  color: black;
}

.info-container.ng-hide {
  opacity:0;
}

.info-text {
  position: absolute;
  top:0px;
  left:0px;
}

.info-icon {
  z-index: 2;
  position: relative;
  top:0px;
  left:0px;
  cursor: help;
}

.top-right {
  position: absolute;
  top:0px;
  right:0px;
  padding: 5px;
  padding: 5px;
  cursor: pointer;
}

.rotate90 {
  transform: translateY(360%) translateX(00%) rotate(90deg);
}

.rotate180 {
  transform: translateY(250%) rotate(180deg);
}
