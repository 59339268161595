/* Buttons */
.select-dark {
  background-color: $main-slate;
  color: $text-light-secondary;
  border-color: $main-black;
}

.input-dark {
  background-color: $main-slate;
  color: $text-light-primary;
  border: 1px solid $main-slate;
  &:focus {
    background-color: $main-black;
  }
}

.input-neutral {
  background-color: $grey-neutral;
  color: $light-neutral;
  border: 1px solid $grey-secondary;
  &:focus {
    background-color: $grey-secondary;
  }
}

input:required:invalid, input:focus:invalid, input.ng-invalid, input.ng-invalid:focus {
    transition: 0.2s ease-in-out;
    box-shadow: 0px 0px 6px 0px $main-coral;
    border: 1px solid $main-coral;
}

/*Overrides default "required" pseudo class selector*/
input:required:valid {
}

input.ng-valid:focus{
  transition: 0.2s ease-in-out;
  box-shadow: 0px 0px 6px 0px $main-swurquoise;
  border: 1px solid $main-swurquoise;
}