/*
  main
*/
$main-black: #212121;
$main-slate: #282828;
$main-swurquoise: #27ccc0;
$main-coral: #FE5D5C;
$light-swurquoise: #99fff4;

/*
  grey
*/
$grey-primary: $main-slate;
$grey-neutral: gray;
$grey-secondary: #6d6e6f;
$grey-thin: #999999;
/*
  light
*/
$light-primary: #f6f6f6;
$light-neutral: white;
$light-secondary: #e8e8e8;
/*
  text dark:
*/
$text-dark-primary: $grey-primary;
$text-dark-secondary: $grey-secondary;
/*
  text light:
*/
$text-light-primary: $light-primary;
$text-light-secondary: $light-neutral;
$text-link: $main-swurquoise;
/*
  brand colors:
*/
$facebook: #4c699e;
$google: #da573b;
$twitter: #1daee3;
$linkedin: #0077B5;
$amazon: #FF9900;


$anim-multiplier: .90;


/* * * * * * * * * * * * * * * * * Bootstrap extras * * * * * * * * * * * * * * */

// Grid breakpoints (bootstrap)
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px
) !default;

// Media queries
//
// Example usage:
// @media all and ($is-md) {
//   ...
// }

$lt-sm: "max-width: #{ map-get($grid-breakpoints, sm) - 1 }";
$lt-md: "max-width: #{ map-get($grid-breakpoints, md) - 1 }";
$lt-lg: "max-width: #{ map-get($grid-breakpoints, lg) - 1 }";
$lt-xl: "max-width: #{ map-get($grid-breakpoints, xl) - 1 }";

$gt-xs: "min-width: #{ map-get($grid-breakpoints, sm) }";
$gt-sm: "min-width: #{ map-get($grid-breakpoints, md) }";
$gt-md: "min-width: #{ map-get($grid-breakpoints, lg) }";
$gt-lg: "min-width: #{ map-get($grid-breakpoints, xl) }";

$is-xs: $lt-sm;
$is-sm: "#{$gt-xs}) and (#{$lt-md}";
$is-md: "#{$gt-sm}) and (#{$lt-lg}";
$is-lg: "#{$gt-md}) and (#{$lt-xl}";
$is-xl: $gt-lg;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$spacers: map-merge(
  (
    0: 0,
    1: ($spacer * .25),
    2: ($spacer * .5),
    3: $spacer,
    4: ($spacer * 1.5),
    5: ($spacer * 3)
  ),
  $spacers
);
