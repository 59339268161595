/* Three card styling */

.threeQuestionContainer {
	width: 100%;
	.row{
		display: flex;
		flex-flow: row nowrap;
		justify-content: space-between;
		align-content: center;
		align-items: center;
		width: 100%;
		margin: 0;
		*{
			display: flex;
			justify-content: center;
			align-content: center;
			align-items: center;
		}
	}
	>.row{
		align-items: flex-start;
	}
	.choice, .left, .right{
		padding: 0;
	}
	.choice{
		width: 36%;
	}
	.binary-dragger {
		flex-flow: column;
		font-size: 0.9em;
		padding: .15em 0 0 0;
		width: 28%;
	}
	.left i{
		margin-right: 0.7em;
	}
	#left-choice{
	}
	.right i{
		margin-left: 0.7em;
	}
	#right-choice{
	}
	
	#bottom-choice{
		padding-top: 0.4em;
		margin:0;
	}
}
