
// Flex elements

.flex {
  display: flex;
}

// Containers

[class*='flex-row'] {
  display: flex;
  flex-direction: row;
}

[class*='flex-column'] {
  display: flex;
  flex-direction: column;
}

// Justification (main axis)

[class*='flex'][class*='space-between'] {
  justify-content: space-between;
}

[class*='flex'][class*='space-around'] {
  justify-content: space-around;
}

[class*='flex'][class*='space-evenly'] {
  justify-content: space-evenly;
}

// Alignment (cross-axis)

[class*='flex'][class*='align-start'] {
  align-items: flex-start;
}

[class*='flex'][class*='align-end'] {
  align-items: flex-end;
}

[class*='flex'][class*='align-center'] {
  align-items: center;
}

[class*='flex'][class*='align-stretch'] {
  align-items: stretch;
}

// Wrap

[class*='flex'][class*='wrap'] {
  flex-wrap: wrap;
}

[class*='flex'][class*='wrap-reverse'] {
  flex-wrap: wrap-reverse;
}

// TODO: factor out general rules above into a mixin
// to be applied within different media query breakpoints

@media all and ($is-xs) {

  [class*='flex-xs-row'] {
    display: flex;
    flex-direction: row;
  }

  [class*='flex-xs-column'] {
    display: flex;
    flex-direction: column;
  }
}

@media all and ($gt-xs) {

  [class*='flex-gt-xs-row'] {
    display: flex;
    flex-direction: row;
  }

  [class*='flex-gt-xs-column'] {
    display: flex;
    flex-direction: column;
  }
}

