/* SWIPE */

$anim-speed: 2.7s * $anim-multiplier;
$chevron-speed: 1.8s * $anim-multiplier;
.animated {
    animation-duration: ($anim-speed * $anim-multiplier);
    animation-fill-mode: both;
}
.animated.infinite {
    animation-iteration-count: infinite;
}
.gradient-puls {
    background: linear-gradient(120deg, $main-slate, $main-swurquoise);
    background-size: 100% 100%;
    animation: gradient-puls $anim-multiplier * 1.5s linear infinite;
}
@-webkit-keyframes gradient-puls {
    0% {
        background-position: 0vw 0;
    }
    90% {
        background-position: 100vw 0;
    }
    100% {
        background-position: 100vw 0;
    }
}
@-moz-keyframes gradient-puls {
    0% {
        background-position: 0vw 0;
    }
    90% {
        background-position: 100vw 0;
    }
    100% {
        background-position: 100vw 0;
    }
}
@keyframes gradient-puls {
    0% {
        background-position: 0vw 0;
    }
    90% {
        background-position: 100vw 0;
    }
    100% {
        background-position: 100vw 0;
    }
}
@keyframes fadeOutUp {
    0% {
        opacity: 0;
        transform: translate(0, -50%);
    }
    30% {
        opacity: 1;
        transform: translate(0, -100%);
    }
    90% {
        opacity: 0;
        transform: translate(0, -150%);
    }
    100% {
        opacity: 0;
        transform: translate(0, 0);
    }
}
.fadeOutUp {
    animation-name: fadeOutUp;
    transition: ease-in;
}

.fade-out {
    opacity: 0;
    transition: $anim-multiplier * 1s;
}

@keyframes bounceOut {
  20% {
    transform: scale3d(.9, .9, .9);
  }

  50%, 55% {
    opacity: 1;
    transform: scale3d(1.1, 1.1, 1.1);
  }

  to {
    opacity: 0;
    transform: scale3d(.3, .3, .3);
  }
}

.bounceOut {
  animation-name: bounceOut;
}



/* WOT */
.wot-animate {
	opacity: 0;
	animation-fill-mode: forwards;
	animation-duration: $anim-multiplier * 1s;
	animation: fade-in-fwd 0.6s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
}

#WOTShare {
	.wot-animate {
		animation-fill-mode: both;
		animation-name: focus-in-expand;
		animation-duration: $anim-multiplier * 1s;
		animation-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
	}
}

#WOTIntro {
	.wot-animate {
		animation-fill-mode: both;
		animation-name: tracking-in-contract-bck-bottom;
		animation-duration: $anim-multiplier * 1s;
		animation-timing-function: cubic-bezier(0.215, 0.610, 0.355, 1.000);
	}
}

/* animation delays */
.delay1 {
    animation-delay: $anim-multiplier * 0.5s;
}
.delay2 {
    animation-delay: $anim-multiplier * 1.0s;
}
.delay3 {
    animation-delay: $anim-multiplier * 1.5s;
}
.delay4 {
    animation-delay: $anim-multiplier * 1.9s;
}


/* Chrome, Safari, Opera */
@-webkit-keyframes fade-in-text {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
/* Standard syntax */
@keyframes fade-in-text {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
/* Chrome, Safari, Opera */
@-webkit-keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
/* Standard syntax */
@keyframes fade-out {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0;
    }
}
/* CHEVRON */
.animated-chevron {
    animation-duration: $chevron-speed;
    animation-iteration-count: infinite;
    animation-play-state: running;
    animation-timing-function: cubic-bezier(0.42, 0, 0.58, 1);
}
.fa-chevron-up {
    animation-name: arrowUpAnim;
}
.up-solo {
    animation-name: arrowUpSoloAnim !important;
}
.fa-chevron-left {
    animation-name: arrowLeftAnim;
}
.fa-chevron-right {
    animation-name: arrowRightAnim;
}
.fa-chevron-down {
    animation-name: arrowDownAnim;
}
@keyframes arrowLeftAnim {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(-5px, 0);
    }
}
@keyframes arrowRightAnim {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(5px, 0);
    }
}
@keyframes arrowUpAnim {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(0, -5px);
    }
}
@keyframes arrowUpSoloAnim {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(0, -20px);
    }
}
@keyframes arrowDownAnim {
    0% {
        transform: translate(0, 0);
    }
    20% {
        transform: translate(0, 5px);
    }
}

/*
  Spinner Animation
*/
/* Transparent Overlay */
/* :not(:required) hides these rules from IE9 and below */
.spinner:not(:required) {
    /* hide "loading..." text */
    font: 0/0 a;
    color: transparent;
    text-shadow: none;
    background-color: transparent;
    border: 0;
}

.spin {
    animation: spinner 2s infinite linear;
}

.spinner:not(:required):after {
    content: '';
    display: block;
    font-size: 10px;
    width: 1em;
    height: 1em;
    margin-top: -0.5em;
    animation: spinner 1500ms infinite linear;
    border-radius: 0.5em;
    box-shadow: $main-swurquoise 1.5em 0 0 0, $main-swurquoise 1.1em 1.1em 0 0, $main-swurquoise 0 1.5em 0 0, $main-swurquoise -1.1em 1.1em 0 0, $main-swurquoise -1.5em 0 0 0, $main-swurquoise -1.1em -1.1em 0 0, $main-swurquoise 0 -1.5em 0 0, $main-swurquoise 1.1em -1.1em 0 0;
}


//These are for hints when
.choice {
    transition: .5s;
    transition-timing-function: cubic-bezier(0.250, 0.460, 0.450, 0.940);
   &.choice-selected, &:hover {
    transform: scale(1.5);
   }
}

/* Animation */
@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

@keyframes shake {
  10%, 90% {
    transform: translate3d(0, 0, 0);
  }

  20%, 80% {
    transform: translate3d(2px, 0, 0);
  }

  30%, 50%, 70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%, 60% {
    transform: translate3d(4px, 0, 0);
  }
}

.please-click-me  {
  //animation: shake 1000ms both infinite;
  animation: shake 1.6s cubic-bezier(.36,.07,.19,.97) both infinite;
  animation-timing-function: ease;
}

@keyframes please-click-me {
  0%, 100% {
    transform: scale3d(1.1, 1.1, 1.1);
  }

  50% {
    transform: scale3d(.9,.9,.9);
  }
}

@-webkit-keyframes focus-in-expand{0%{-webkit-filter:blur(10px);filter:blur(10px);opacity:0}100%{-webkit-filter:blur(0);filter:blur(0);opacity:1}}@keyframes focus-in-expand{0%{-webkit-filter:blur(10px);filter:blur(10px);opacity:0}100%{-webkit-filter:blur(0);filter:blur(0);opacity:1}}
@-webkit-keyframes tracking-in-contract-bck-bottom {
	0% {
		letter-spacing: 1em;
		-webkit-transform: translateZ(55vh) translateY(72vh);
		transform: translateZ(55vh) translateY(72vh);
		opacity: 0
	}
	40% {
		opacity: .6
	}
	100% {
		-webkit-transform: translateZ(0) translateY(0);
		transform: translateZ(0) translateY(0);
		opacity: 1
	}
}
@keyframes tracking-in-contract-bck-bottom {
	0% {
		letter-spacing: 1em;
		-webkit-transform: translateZ(55vh) translateY(72vh);
		transform: translateZ(55vh) translateY(72vh);
		opacity: 0
	}
	40% {
		opacity: .6
	}
	100% {
		-webkit-transform: translateZ(0) translateY(0);
		transform: translateZ(0) translateY(0);
		opacity: 1
	}
}
@-webkit-keyframes slide-in-elliptic-left-fwd{0%{-webkit-transform:translateX(-800px) rotateY(30deg) scale(0);transform:translateX(-800px) rotateY(30deg) scale(0);-webkit-transform-origin:-100% 50%;transform-origin:-100% 50%;opacity:0}100%{-webkit-transform:translateX(0) rotateY(0) scale(1);transform:translateX(0) rotateY(0) scale(1);-webkit-transform-origin:1800px 50%;transform-origin:1800px 50%;opacity:1}}@keyframes slide-in-elliptic-left-fwd{0%{-webkit-transform:translateX(-800px) rotateY(30deg) scale(0);transform:translateX(-800px) rotateY(30deg) scale(0);-webkit-transform-origin:-100% 50%;transform-origin:-100% 50%;opacity:0}100%{-webkit-transform:translateX(0) rotateY(0) scale(1);transform:translateX(0) rotateY(0) scale(1);-webkit-transform-origin:1800px 50%;transform-origin:1800px 50%;opacity:1}}
@-webkit-keyframes slide-in-elliptic-right-fwd{0%{-webkit-transform:translateX(800px) rotateY(-30deg) scale(0);transform:translateX(800px) rotateY(-30deg) scale(0);-webkit-transform-origin:-100% 50%;transform-origin:-100% 50%;opacity:0}100%{-webkit-transform:translateX(0) rotateY(0) scale(1);transform:translateX(0) rotateY(0) scale(1);-webkit-transform-origin:-1800px 50%;transform-origin:-1800px 50%;opacity:1}}@keyframes slide-in-elliptic-right-fwd{0%{-webkit-transform:translateX(800px) rotateY(-30deg) scale(0);transform:translateX(800px) rotateY(-30deg) scale(0);-webkit-transform-origin:-100% 50%;transform-origin:-100% 50%;opacity:0}100%{-webkit-transform:translateX(0) rotateY(0) scale(1);transform:translateX(0) rotateY(0) scale(1);-webkit-transform-origin:-1800px 50%;transform-origin:-1800px 50%;opacity:1}}
@-webkit-keyframes fade-in-fwd{0%{-webkit-transform:translateZ(-80px);transform:translateZ(-80px);opacity:0}100%{-webkit-transform:translateZ(0);transform:translateZ(0);opacity:1}}@keyframes fade-in-fwd{0%{-webkit-transform:translateZ(-80px);transform:translateZ(-80px);opacity:0}100%{-webkit-transform:translateZ(0);transform:translateZ(0);opacity:1}}

// Angular animations (see https://docs.angularjs.org/api/ngAnimate)

.animate-show-hide {
    &.ng-hide {
        opacity: 0;
        max-height: 0;
        padding-top: 0;
        padding-bottom: 0;
    }
    &:not(.ng-hide) {
        max-height: 100vh;
    }

    &.ng-hide-add,
    &.ng-hide-remove {
        transition: all ease-in-out 0.5s;

        &.quick {
            transition: all ease-in-out 0.25s;
        }
    }
}
