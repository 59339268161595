#WOTIntro{
	max-height: 100%;
	p{
		font-size: 2.5em;
	}
}

#WOTShare {
	color: $text-light-primary;
	position: absolute;
	top: 0px;
	left: 0px;
	width: 100%;
	height: 100%;
	text-align: center;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-content: center;
	align-items: center;
	padding-top: 1.6em;

	transition: all cubic-bezier(0.250, 0.460, 0.450, 0.940) $anim-multiplier * 0.9s;
	overflow-y: scroll;

	@include custom-scroll-bar;
	font-size: 1.8vh;

	>*{
		width: 92%;
	}
}

#WOTShare.ng-hide {
	left: -100%;
}

#WOTPercentage {
	font-size: 6.5em;
	color: $main-swurquoise;
}
.WOTText {
	color: $grey-neutral;
	font-size: 1.5em;
}
#WOTAnswer {
	margin-top: 0;
	font-size: 2em;
}
.WOTMainText {
	color: $text-light-secondary;
	font-size: 2em;
}
.noWOTCustomMessage {
	color: $text-light-secondary;
}

.wot-custom-message {
	font-size: 3.5em;
}

#WOTAction .WOTText {
	margin-bottom: 1rem;
}

.wot-actions {
	margin-top: 3em;
}

.wot-text {
  font-size: 4em;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-bottom: auto;
}

.wot-text p {
  line-height: 1em;
  padding: 0;
}

.dataLink {
	color: $main-black;
	margin: 1.8em 0;
}
.wot-link {
	border: solid 0.12em;
	font-size: 1.5em;
	margin-bottom: .5em;

	&.full-width {
		display: block;
	}
}

#WOTBar {
	padding-top: 1%;
}

.socialcircle {
	background-color: $main-swurquoise;
	border-radius: 4em;
	height: 3.8em;
	width: 3.8em;
	margin: 0.7em;
	display: inline-block;
}

.socialcircle > div {
	padding: 50% 0;
	line-height: 0;
	color: $text-light-secondary;
}

.facebook-circle {
    background-color: $facebook;
}

.twitter-circle {
    background-color: $twitter;
}

.mail-circle {
    background-color: $main-swurquoise;
}

.sms-circle {
    background-color: $main-slate;
}


