/*
* Base styling for cards
*/



$cardRatio: 1.412;
$cardHeight: 60vh;



/* outer most card container */
.cards {
  position: relative;
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;

	/* inner card container */
	.card-wrap {
		position: relative;
		height: $cardHeight;
		width: $cardHeight / $cardRatio;
		max-width: 92%;
		font-size: 1.6vh;
		display: flex;
		justify-content: center;
		align-content: center;
		align-items: center;

		.shake {
			animation: shake 2s cubic-bezier(.36,.07,.19,.97) both infinite;
			animation-delay: 3s;
			transform: translate3d(0, 0, 0);
			backface-visibility: hidden;
			perspective: 1000px;
			&:active {
				animation: none;
			}
		}

		/* active card */
		.card {
			z-index: 1;
			position: relative !important;
			width: 100%;
			height: 100%;
			//border-style: none;
			font-size: 2.3em;
			text-align: center;
			overflow: hidden;
			border-radius: 14px;
			// border-radius: 3.2%;
			display: flex;
			flex-flow: column;
			justify-content: space-between;


			.card-top-img{
				position: absolute;
				border-radius: 0 ;
				width: 102% ;
				height: 102% ;
				top: -1%;
				bottom: -1%;
				left: -1%;
				right: -1%;
				-webkit-background-size: cover;
				-moz-background-size: cover;
				-o-background-size: cover;
				background-size: cover;
			}


		}

	}


}


.card-text {
	width: 100%;
	max-height: 90%;
	flex-grow: 1;
	flex-shrink: 1;
	display: flex;
	flex-flow: column;
	overflow-y: hidden;
	.inner-text{
		padding: 4% 3% 2%;
		p{
			margin: 0;
		}
	}
}

.card-logo-image {
	width:100%;
	height:15%;
	background-position: center;
	background-repeat: no-repeat;
	background-size: contain;
	margin-top: 5%;
}

.answer-widget {
	width: 100%;
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	justify-content: flex-end;
	margin-top: 3%;
	padding: 0 2%;

	>span{
		height: 100%;
		width: 100%;
		display: flex;
		flex-grow: 2;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		>section{
			width: 100%;
			max-height: 100%;
			display: flex;
			flex-direction: column;
			justify-content: flex-end;
		}
	}

	.card-action-hint{
		padding-top: 8%;
		height: 6%;
		width: 100%;
		margin: 0;
	}

	.swipe-text {
		font-size: .5em !important;
		font-weight: 600;
		opacity: 0.7;
		margin: 0.1em 0 0;
		text-align: center !important;
	}

	#top-choice,#right-choice,#bottom-choice,#left-choice{
		p:hover{
			cursor: pointer;
		}
	}

}





/* card transitions */
.card.ng-enter {
	animation: slide-in-elliptic-left-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}
.card.ng-leave {
	animation: slide-in-elliptic-right-fwd 0.7s cubic-bezier(0.250, 0.460, 0.450, 0.940) reverse both;
}





//darken background when Swurveys SignUp card is up
.swurve-overlay {
	z-index: 1;
	content: '';
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	background: rgba(0, 0, 0, 0.9);
	left: 0;
	.ng-enter {
	 transition:.7s ease-out all;  
	 background: rgba(0, 0, 0, 0);
	}
	.ng-enter-active {
	 background: rgba(0, 0, 0, 0.5);
	}

	.ng-leave {
	 transition:1s ease-out all;
	 background: rgba(0, 0, 0, 0.5);
	}
	.ng-leave-active {
	 background: rgba(0, 0, 0, 0);
	}
}



//leadGen & extendedFeedback cards
.card {
	form {
		height: 100%;
		width: 100%;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
		align-items: center;
		flex-grow: 2;

		textarea { //extendedFeedback card
			width: 96%;
			resize: none;
			font-size: 0.8em;
			text-align: center;
			flex-grow: 2;
			border-radius: 0.2em;
			padding: 0.2em 0.3em;
		}
		input {  //leadgen
			z-index: 5;
			width: 96%;
			height: 1.7em;
			font-size: 0.7em;
			vertical-align: top;
			line-height: normal;
			text-align: center;
			padding: 0.9em 0;
			margin-bottom: 0.5em;
			border-radius: 0.4em;
			border: 1px solid #bcbcbc;
			&::placeholder {
				font-style: italic;
			}
		}

		button {
			width: 95%;
			font-size: 0.6em;
			outline: none;
			border: 0.2em solid transparent;
			border-radius: 0.4em;
			padding: 0.36em 0.4em;
			margin-bottom: 0.1em;
		}

	}
}

//Two buttons rotate around eachother based upon the 'flipped' class.
//This is used for leadgen/etc
.cube {
	height: 1.8em;
	margin-bottom: 0.1em;
	transition: all 250ms ease;
	transform-style: preserve-3d;

	&.flipped{
		transform: rotateX(-89deg);
	}
}
	
.flip-button-default {
	transform: translateZ(2.2em);
}

.flip-button-flipped {
	transform: rotateX(90deg) translateZ(1.8em);
}



.signupCard, .signup {
	opacity: 0.96;
	font-family: "Source Sans Pro";
	line-height: .9em;

	img {
		height: initial;
		width: 75%;
		padding-top: 10%;
		margin-bottom: 20%;
	}
	.card-top-bg {
		padding: 0;
		margin: 0;
		background-image: url(https://app.swurveys.com/assets/swurveys/swurveys-squiggly.svg);
		background-position: 50% 65%;
		background-size: 120%;
		background-repeat: no-repeat;
		background-color: #212121;
	}
	.inner-text p {
		padding: 0% 10% 0% 10%;
	}
	.card-text, .hint-text {
		color: white;
	}
}






// for referral card
#signupButton {
	width: 56%;
	font-size: 0.8em;
	padding: 0.2em;
	border-width: 0.2em;
	border-radius: 0.4em;
	justify-content:center;
	align-items:center;
	margin-bottom: 2.4em;
}

